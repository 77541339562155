html, body {
  width: 100%;
  height: 100%;
}

body {
  max-width: 640px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./vaultboy-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: top left;
  overflow: hidden;
}

#container {
  height: 100%;
  overflow-x: auto;
  overflow-y: scroll;
}

.container {
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 20px #61de2633, 0 0 10px #61de2633, 0 0 5px #61de2633 inset;
  margin: 1rem;
  padding: 1rem;
  border-radius: 1rem;
}

.container h1 {
  margin-top: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin-top: 0;
}