body, input, button {
  font-size: 18px;
}

h1 {
  font-size: 140%;
}

#codes button {
  border-radius: 0.5rem;
  padding: 0.2rem 0.5rem;
  border: solid 1px #222;
}

#codes table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

#codes table th,
#codes table td {
  text-align: left;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

#codes table tbody > tr:nth-child(even) td {
  background-color: #f1f1f1;
}

#codes table .btn {
  text-align: right;
  width: 2rem;
}

#codes table .code {
  width: 100%;
}

#codes table .code .excluded {
  text-decoration: line-through;
  opacity: 0.5;
}

#codes table td.value button {
  background-color: #f1f1f1;
  color: #222;
}

#codes table tr.used td.value button.selected {
  background-color: #222;
  color: white;
}

#codes table thead th {
  border-bottom: solid 1px black;
}

#codes table tfoot th {
  border-top: solid 1px black;
}

#codes table tfoot th input {
  width: 100%;
  transition-property: all;
  transition-duration: 500ms;
}

#codes table tfoot th input:hover,
#codes table tfoot th input:focus,
#codes table tfoot th input:active {
  outline: solid 1px #61de26;
  border-color: #61de26;
  background-color: #61de2633;
}

#codes button.remove {
  color: white;
  background-color: #f32;
}